import React from "react";
import { Layout } from "@components/Layout"
import ROIHero from "../../../components/Calculator/ROIHero";
import { graphql } from "gatsby";
import IconBlock from "../../../components/IconBlock/IconBlock";
import ConversionPanel from "../../../components/ConversionPanel/ConversionPanel";

export default function ROICalculatorDetailPage({ data }) {
  const {
    contentfulComponentIconBlock,
    contentfulComponentConversionPanel,
  } = data

  return (
    <Layout>
      <ROIHero heading="Detailed ROI Report" detail />
      <IconBlock component={contentfulComponentIconBlock} />
      <ConversionPanel component={contentfulComponentConversionPanel} />
    </Layout>
  )
}

export const roiCalculatorDetailPageQuery = graphql`
  query roiCalculatorDetailPageQuery {
    contentfulComponentIconBlock(
      id: { eq: "a17a01de-c92a-5b5a-b367-99b7c940c25d" }
    ) {
      __typename
      contentful_id
      internalName
      heading
      kicker
      type
      background
      noTopPadding
      noBottomPadding
      unrestrictImageHeight
      backgroundImage {
        id
        primaryImage {
          gatsbyImageData
          file {
            url
          }
        }
      }
      subheading {
        subheading
      }
      callsToAction {
        contentful_id
        id
        internalLink
        label
        type
        link
        variant
      }
      icons {
        id
        alignCaption
        caption {
          raw
        }
        description
        internalLink
        internalName
        primaryImage {
          gatsbyImageData
          file {
            url
            contentType
          }
          description
        }
        title
        url
        featureGridTitle
        featureGridDescription
      }
      featuredPersona {
        ... on ContentfulComponentGeneric {
          id
          align
          background
          callsToAction {
            variant
            type
            link
            label
            id
            internalName
            internalLink
            contentful_id
          }
          kicker
          heading
          headingSize
          subheading {
            raw
          }
          bodyCopy {
            raw
          }
          featuredImage {
            gatsbyImageData
            file {
              url
            }
            title
            description
            contentful_id
          }
        }
      }
    }
    contentfulComponentConversionPanel(
      id: { eq: "e67bca14-7e32-59ee-9b6a-b5b267844cd8" }
    ) {
      kicker
      __typename
      id
      textAlign
      alignContent
      background
      noTopPadding
      noBottomPadding
      subheading {
        raw
      }
      callsToAction {
        contentful_id
        id
        internalLink
        label
        type
        link
        variant
      }
      headingSize
      heading
      type
    }
  }
`
